import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoginOrSignUpMutation } from '../../../service/appAuth.service';

const LoginOrSignupDialog = ({ open, onClose }) => {
	const [loginOrSignUp] = useLoginOrSignUpMutation();
	const [searchParams] = useSearchParams();
	const forwardPath = searchParams.get('forwardPath') || localStorage.getItem('forwardPath');
	const navigate = useNavigate();

	const googleResponse = async (res) => {
		if (res) {
			let data = res;
			data.browserVersion = navigator.userAgent;
			data.appVersion = process.env.REACT_APP_VERSION;
			let OSName = '';
			if (navigator.userAgent.indexOf('Win') !== -1) OSName = 'Windows OS';
			if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Macintosh';
			if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux OS';
			if (navigator.userAgent.indexOf('Android') !== -1) OSName = 'Android OS';
			if (navigator.userAgent.indexOf('like Mac') !== -1) OSName = 'iOS';

			data.OSVersion = OSName;

			await loginOrSignUp({ data });

			onClose();

			if (forwardPath) {
				navigate(forwardPath);
			}

			localStorage.removeItem('forwardPath');
			localStorage.removeItem('triggerLogin');
		}
	};

	const loginOrSignUpCallBack = useGoogleLogin({
		onSuccess: googleResponse,
		error_callback: onClose
	});

	useEffect(() => {
		if (open) {
			loginOrSignUpCallBack();
		}
	}, [open]);

	return (
		<>
			{/* <Dialog maxWidth="sm" fullWidth TransitionComponent={PopupTransition} onClose={onClose} open={open} sx={{ '& .MuiDialog-paper': { p: 0 } }} scroll={'paper'}>
				<div className="flex items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8 relative">
					<div className="absolute top-4 right-4 cursor-pointer">
						<CloseOutlined onClick={onClose} />
					</div>

					<div className="max-w-md w-full space-y-8">
						<div>
							<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{isSignUpWorkFlow ? 'Sign up with' : 'Sign in with'}</h2>
						</div>

						<div className="rounded-md shadow">
							<button
								onClick={() => loginOrSignUpCallBack()}
								className="w-full flex justify-center items-center py-4 px-8 border border-black rounded-md shadow-sm text-sm font-normal text-black focus:outline-none focus:ring-2 focus:ring-offset-2 relative">
								<img className="w-6 h-6 mr-2 absolute left-2" src={googleIcon} alt="" />
								<span className="absolute left-50 transform -translate-x-50">Google</span>
							</button>
						</div>

						{!isSignUpWorkFlow ? (
							<div>
								<p className="mt-2 text-center text-sm text-gray-600">
									Don't have an account?&nbsp;
									<button role="link" onClick={() => setIsSignUpWorkFlow(!isSignUpWorkFlow)} className="font-normal text-blue-600 hover:text-blue-500">
										Sign Up
									</button>
								</p>
							</div>
						) : (
							<div>
								<p className="mt-2 text-center text-sm text-gray-600">
									Already have an account?&nbsp;
									<button role="link" onClick={() => setIsSignUpWorkFlow(!isSignUpWorkFlow)} className="font-normal text-blue-600 hover:text-blue-500">
										Login
									</button>
								</p>
							</div>
						)}
					</div>
				</div>
			</Dialog> */}
		</>
	);
};

export default LoginOrSignupDialog;
