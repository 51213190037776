import React from 'react';
import { useSelector } from 'react-redux';
import EventAttendeesListing from './EventAttendeesListing';
import EventOverview from './EventOverview';

const EventDetail = () => {
	const eventDetail = useSelector((state) => state.eventDetailSlice.eventDetail);
	const eventAttendees = useSelector((state) => state.eventDetailSlice.eventAttendees.listing.attendees);

	return (
		<div className="bg-[#fffdfd]">
			<div className="container mx-auto flex-col xl:items-center flex justify-center min-h-[80vh] px-4 py-8 md:px-16 md:py-16 gap-6">
				<div className="xl:w-[660px] bg-[#e6eef4] md:rounded-xl drop-shadow-lg p-4 md:p-8">
					<EventOverview eventData={eventDetail} />
				</div>
				<div className="xl:w-[660px] md:rounded-xl drop-shadow-lg p-2 bg-[#fff5f5] my-2">
					<EventAttendeesListing eventAttendees={eventAttendees} />
				</div>
			</div>
		</div>
	);
};

export default EventDetail;
