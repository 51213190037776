import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const SectionLoader = ({ open }) => {
	return (
		<Backdrop
			sx={{
				color: '#fff',
				zIndex: (theme) => theme.zIndex.drawer + 1,
				background: 'transparent',
				'.MuiBackdrop-root': {
					backgroundColor: 'transparent'
				}
			}}
			open={open}>
			<CircularProgress color="primary" />
		</Backdrop>
	);
};

export default SectionLoader;
