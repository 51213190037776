import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { outGoingRequestApiService } from './outgoingRequest.service';

const initialState = {
	listing: {
		paginationParams: {
			offset: 0,
			limit: 10,
			searchString: ''
		}
	},
	requests: [],
	totalRequestsCount: 0
};

const outgoingRequestsSlice = createSlice({
	name: 'outgoingRequestsSlice',
	initialState,
	reducers: {
		resetOutGoingRequest: () => cloneDeep(initialState),
		setOutGoingRequestPaginationParams: (state, action) => {
			state.listing.paginationParams = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(outGoingRequestApiService.endpoints.fetchUserOutgoingRequest.matchFulfilled, (state, { payload }) => {
			state.requests = payload.rows;
			state.totalRequestsCount = payload.count;
		});
	}
});

export default outgoingRequestsSlice.reducer;

export const { setOutGoingRequestPaginationParams, resetOutGoingRequest } = outgoingRequestsSlice.actions;
