const appInternalRoutes = {
	HOME_ROUTE: '/',
	LOGIN_ROUTE: '/login',
	SIGNUP_ROUTE: '/signup',
	EVENTS_LISTING: '/events',
	USER_PROFILE: '/profile',
	CHAT_ROUTE: '/chat',
	CONNECTIONS_ROUTE: '/connections'
};
export default appInternalRoutes;
