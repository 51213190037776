import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { jwtDecode } from 'jwt-decode';
import { appApiRoutes } from '../data/appApiRoutes';

export const userApiService = createApi({
	reducerPath: 'userApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BACKEND_URL}`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().appAuth.token;

			if (token) {
				const decodedToken = jwtDecode(token);
				const dateNow = new Date();
				const tokenExpired = decodedToken.exp < dateNow.getTime() / 1000;
				if (!tokenExpired) {
					headers.set('access-token', `Bearer ${token}`);
				}
			}
			return headers;
		}
	}),
	tagTypes: ['User'],
	endpoints: (builder) => ({
		loginOrSignUp: builder.mutation({
			query: ({ data }) => ({
				url: `${appApiRoutes.registerUser}`,
				method: 'POST',
				body: data
			})
		}),
		updateUser: builder.mutation({
			query: ({ data }) => ({
				url: appApiRoutes.updateUser,
				method: 'PATCH',
				body: data
			})
		})
	})
});

export const { useLoginOrSignUpMutation, useUpdateUserMutation } = userApiService;
