import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IndividualAttendee, NoResultFound, SectionLoader } from '../../../components/UI';
import ListPagination from '../../../components/UI/ListPagination';
import { genericPaginationParams } from '../../../data/constants';
import { triggerImpactOfSearchChangeToRedux } from '../../../data/utils';
import DeleteUserConfirmationDialog from './DeleteUserConfirmationDialog';
import { useAcceptOrRejectMatchRequestMutation, useFetchUserIncomingRequestQuery } from './service/incomingRequest.service';
import { setIncomingRequestPaginationParams } from './service/incomingRequest.slice';

const IncomingTab = () => {
	const incomingRequestPaginationParams = useSelector((state) => state.incomingRequestSlice?.listing?.paginationParams);
	const { isLoading } = useFetchUserIncomingRequestQuery({ ...incomingRequestPaginationParams }, { refetchOnMountOrArgChange: true });
	const incomingRequest = useSelector((state) => state.incomingRequestSlice.requests);
	const [searchString, setSearchString] = useState('');
	const [acceptOrRejectMatchRequest] = useAcceptOrRejectMatchRequestMutation();
	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
	const [selectedForDeletion, setSelectedForDeletion] = useState();
	const navigate = useNavigate();
	const handleEventsSearch = (e) => {
		setSearchString(e.target.value);
		triggerImpactOfSearchChangeToRedux({
			payload: genericPaginationParams,
			value: e.target.value,
			dispatchEvent: setIncomingRequestPaginationParams
		});
	};

	const handleAcceptOrRejectRequest = async (eventId, userId, status) => {
		await acceptOrRejectMatchRequest({
			data: {
				eventId,
				userId,
				status
			}
		});
	};

	return (
		<>
			{(searchString === null || searchString === '') && !isLoading && incomingRequest?.length === 0 ? null : (
				<Stack className="flex items-stretch justify-center mb-3" spacing={2}>
					<TextField value={searchString} onChange={handleEventsSearch} placeholder="Search Incoming Requests" variant="outlined" type="search" />
				</Stack>
			)}

			{isLoading ? (
				<SectionLoader open={isLoading} />
			) : !isLoading && incomingRequest?.length === 0 ? (
				<div className="xl:-mb-[32px]">
					<NoResultFound
						showActionButton
						buttonClickHandler={() => {
							navigate('/events');
						}}
						message={<p>Sorry, but there are no incoming requests at the moment.</p>}
					/>
				</div>
			) : (
				<>
					<div className="mt-6">
						{incomingRequest?.map((attendee) => (
							<IndividualAttendee
								// onDeleteButtonClick={() => handleAcceptOrRejectRequest(attendee.eventId, attendee.id, 'REJECTED')}
								onDeleteButtonClick={() => setSelectedForDeletion(attendee)}
								addUserDeletion
								onActionButtonClick={() => handleAcceptOrRejectRequest(attendee.eventId, attendee.id, 'MATCHED')}
								addBorderBottom
								attendee={attendee}
								key={attendee.id}
								actionButtonText="Accept"
								addEventname
							/>
						))}
					</div>

					<Stack className="pt-4 flex items-center justify-center" spacing={2}>
						<ListPagination dispatchPaginationReducer={setIncomingRequestPaginationParams} totalRowCount={10} pageSize={10} />
					</Stack>
				</>
			)}

			<DeleteUserConfirmationDialog
				open={selectedForDeletion}
				handleClose={() => setSelectedForDeletion()}
				handleConfirm={() => {
					handleAcceptOrRejectRequest(selectedForDeletion.eventId, selectedForDeletion.id, 'REJECTED');
				}}
			/>
		</>
	);
};

export default IncomingTab;
