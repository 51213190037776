import { createSlice } from '@reduxjs/toolkit';
import { userApiService } from './appAuth.service';

export const appAuth = createSlice({
	name: 'appAuth',
	initialState: {
		user: JSON.parse(localStorage.getItem('currentLoggedInUser')) || null,
		token: localStorage.getItem('appToken') || null,
		tokenExpired: false
	},
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setTokenExpired: (state, action) => {
			state.tokenExpired = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(userApiService.endpoints.loginOrSignUp.matchFulfilled, (state, { payload }) => {
			state.token = payload.authToken;
			state.user = payload.userSummary;
			localStorage.setItem('appToken', payload.authToken);
			localStorage.setItem('currentLoggedInUser', JSON.stringify(payload.userSummary));
		});

		builder.addMatcher(userApiService.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
			state.user = payload.userSummary;
			localStorage.setItem('currentLoggedInUser', JSON.stringify(payload.userSummary));
		});

		builder.addMatcher(userApiService.endpoints.updateUser.matchRejected, (state) => {
			state.tokenExpired = true;
		});
	}
});

export const { setToken, setUser, setTokenExpired } = appAuth.actions;

export default appAuth.reducer;
