import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDefaultLayout } from '../../components/Layout';
import { SectionLoader } from '../../components/UI';
import EventDetail from './EventDetail';
import { useFetchEventAttendeesQuery, useFetchEventByIdQuery } from './service/eventDetail.service';

const EventDetailPage = () => {
	const { id } = useParams();
	const eventAttendeePaginationParams = useSelector((state) => state.eventDetailSlice.eventAttendees.listing.paginationParams);

	const { isLoading } = useFetchEventByIdQuery({ id });
	const { isLoading: eventAttendeesLoading } = useFetchEventAttendeesQuery(
		{
			eventId: id,
			...eventAttendeePaginationParams
		},

		{
			refetchOnMountOrArgChange: true
		}
	);

	const eventDetail = useSelector((state) => state.eventDetailSlice.eventDetail);

	const TITLE = !isLoading && !eventAttendeesLoading ? eventDetail?.eventName : '';

	return (
		<>
			{isLoading || eventAttendeesLoading ? (
				<SectionLoader open={isLoading || eventAttendeesLoading} />
			) : (
				<>
					<Helmet>
						<title>{TITLE}</title>
					</Helmet>
					<AppDefaultLayout>
						<div className="bg-[#60acc6]">
							<div className="container mx-auto flex md:items-center md:justify-center w-full h-min md:h-[100px]  bg-cover bg-no-repeat p-4 sm:p-8 md:p-0">
								{/* <ScreenAttentionSection> */}
								<div className="md:w-[660px] space-y-2">
									<p className="text-2xl md:text-3xl font-normal">{eventDetail.eventName}</p>
									{/* <div className="flex items-center font-normal text-[14px]">
										<span>{moment(eventDetail?.eventStartDate)?.format('dddd')}</span>
										<div className="mx-2 h-1 w-1 bg-[#fff] rounded-full" />
										<span>
											{moment(eventDetail?.eventStartDate)?.format('MMM')} {moment(eventDetail?.eventStartDate)?.date()}
										</span>
										<div className="mx-2 h-1 w-1 bg-[#fff] rounded-full" />
										<span>{moment(eventDetail?.eventStartDate)?.format('hh:mm A')}</span>
									</div> */}
								</div>
							</div>

							{/* </ScreenAttentionSection> */}
						</div>
						<EventDetail />
					</AppDefaultLayout>
				</>
			)}
		</>
	);
};

export default EventDetailPage;
