import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { AppDefaultLayout } from '../../components/Layout';
import Chat from './Chat';

const pubnub = new PubNub({
	publishKey: 'pub-c-2df6a090-43e9-4481-936e-06d471739fdf',
	subscribeKey: 'sub-c-08fd864c-394e-4585-95fd-ff6aab679b83',
	userId: '1'
});

const ChatViewContainer = () => {
	const TITLE = 'Chat';
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const attendeeString = params.get('attendee');
	const attendee = attendeeString ? JSON.parse(decodeURIComponent(attendeeString)) : null;

	return (
		<>
			<Helmet>
				<title>{TITLE}</title>
			</Helmet>
			<AppDefaultLayout>
				<div className="bg-[#60acc6]">
					<div className="flex md:items-center md:justify-center w-full h-min md:h-[100px] container mx-auto bg-cover bg-no-repeat p-4 sm:p-8 md:p-0">
						<div className="md:w-[660px] text-white">
							<div className="flex items-center space-x-5">
								<img loading="lazy" alt="" className="border border-white h-[50px] w-[50px] rounded-full" src={attendee?.avatarUrl || ''} />
								<div className="space-y-2">
									<p className="text-3xl font-normal">{attendee?.fullName}</p>
									<div className="flex items-center font-normal text-[14px]">
										<span>{attendee?.gender}</span>
										{attendee?.gender && attendee?.cityOfResidence && <div className="mx-2 h-1 w-1 bg-[#fff] rounded-full" />}
										<span>{attendee?.cityOfResidence}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<PubNubProvider client={pubnub}>
					<Chat />
				</PubNubProvider>
			</AppDefaultLayout>
		</>
	);
};

export default ChatViewContainer;
