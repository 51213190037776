import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import Datepicker from 'react-tailwindcss-datepicker';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { genericPaginationParams } from '../../data/constants';
import { triggerImpactOfSearchChangeToRedux } from '../../data/utils';
import { setEventListPaginationParams } from '../../features/EventsListing/service/events.slice';

const SearchBar = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchString, setSearchString] = useState(searchParams.get('value') || '');
	const [value, setValue] = useState({
		startDate: parseFloat(searchParams.get('searchDateTimeInMs')) ? dayjs(parseFloat(searchParams.get('searchDateTimeInMs'))) : null,
		endDate: parseFloat(searchParams.get('searchDateTimeInMs')) ? dayjs(parseFloat(searchParams.get('searchDateTimeInMs'))) : null
	});

	const navigate = useNavigate();

	const handleValueChange = (newValue) => {
		setValue(newValue);
	};

	const handleFormSubmit = () => {
		// var startOfDayInMs = value.startDate ? moment(value.startDate).toDate() : null;
		// startOfDayInMs.setHours(0);
		// startOfDayInMs.setMinutes(0);
		// startOfDayInMs.setSeconds(0);
		// startOfDayInMs.setMilliseconds(0);

		// const startOfDayInMs = value.startDate ? new Date(value.startDate.getFullYear(), value.startDate.getMonth(), value.startDate.getDate()).getTime() : null;

		triggerImpactOfSearchChangeToRedux({
			payload: value.startDate
				? {
					...genericPaginationParams,
					searchDateTimeInMs: value.startDate ? new Date(value.startDate).getTime() : null,
					currentPage: 1
				}
				: {
					...genericPaginationParams,
					currentPage: 1
				},
			value: searchString,
			dispatchEvent: setEventListPaginationParams
		});
		// navigate('/events');
		navigate(`/events?searchDateTimeInMs=${new Date(value.startDate).getTime()}&value=${searchString}`);
	};

	const handleEnterKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleFormSubmit();
		}
	};

	useEffect(() => {
		if (value.startDate || searchString) {
			setSearchParams({ searchDateTimeInMs: new Date(value.startDate).getTime(), value: searchString });
		}
		
		triggerImpactOfSearchChangeToRedux({
			payload: value.startDate
				? {
					...genericPaginationParams,
					searchDateTimeInMs: value.startDate ? new Date(value.startDate).getTime() : null,
					currentPage: 1
				}
				: {
					...genericPaginationParams,
					currentPage: 1
				},
			value: searchString,
			dispatchEvent: setEventListPaginationParams
		});
	}, [value, searchString]);

	return (
		<div className="bg-[#93cbee] w-full md:w-[70%] text-white">
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleFormSubmit();
				}}
				onKeyDown={handleEnterKeyPress}
				className="flex flex-col lg:flex-row lg:items-center rounded-2xl p-4 lg:p-0 justify-stretch">

				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						sx={{
							width: '100%',
							scrollbarWidth: '50px !important',
							color: 'white',
							fontSize: '14px',
							'& ::-webkit-scrollbar': {
								width: '8px !important',
								backgroundColor: 'transparent !important'
							},
							'& ::-webkit-scrollbar-thumb': {
								backgroundColor: '#93cbee',
								borderRadius: '4px'
							},
							'& ::-webkit-scrollbar-thumb:hover': {
								backgroundColor: '#93cbee',
								backgroundClip: 'padding-box !important'
							},
							'& ::-webkit-scrollbar-track': {
								backgroundColor: 'transparent !important'
							}
						}}
						value={value?.startDate}
						onChange={(value) => {
							// const selectedDate = new Date(value);
							setValue({ startDate: value || null, endDate: value || null });
						}}
						slotProps={{
							field: {
								clearable: true,
								onClear: () => {
									setValue({ startDate: null, endDate: null });
									setSearchParams({ searchDateTimeInMs: null});
								}
							}
						}}
						renderInput={(params) => <TextField {...params} style={{ color: `white !important`, fontSize: '14px !important' }} />}
					/>
				</LocalizationProvider>
				{/* <Datepicker
					displayFormat="MM/DD/YYYY"
					minDate={moment().toDate()}
					useRange={false}
					asSingle={true}
					toggleClassName={'absolute right-0 h-full px-3 text-white focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'}
					separator="to"
					placeholder="Select Date"
					value={value}
					onChange={handleValueChange}
					primaryColor="blue"
					inputClassName={'bg-[#93cbee] p-3 focus:outline-none focus:ring-0 text-white placeholder:text-white'}
				/> */}

				<div className="flex items-center justify-between w-[100%]">
					{/* <button type="submit" className="bg-[#f3d24e] text-white md:mr-4 rounded-2xl w-full lg:w-fit my-4 md:my-0">
					{/* <button type="submit" className="bg-[#f3d24e] text-white md:mr-4 rounded-2xl w-full lg:w-fit my-4 md:my-0">
					<span className="px-3 py-2 block"></span>
				</button> */}
					<input
						onChange={(e) => {
							setSearchString(e.target.value);
							setSearchParams({ value: e.target.value });
						}}
						value={searchString}
						className="flex-grow p-2 w-full focus:outline-none focus:ring-0 bg-[#93cbee] placeholder:text-white"
						placeholder="Search by artists and events"
						type="search"
					/>

					<SearchIcon
						onClick={(e) => {
							e.preventDefault();
							handleFormSubmit();
						}}
						role="button"
						type="button"
						className="md:mx-4 lg:w-fit my-4 md:my-0 inline-block"
					/>
				</div>
			</form>
		</div>
	);
};

export default SearchBar;
