import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { appApiRoutes } from '../../../data/appApiRoutes';
import { prepareHeadersForAPICall } from '../../../data/utils';

export const eventDetailApiService = createApi({
	reducerPath: 'eventDetailApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BACKEND_URL,
		prepareHeaders: (headers, { getState }) => {
			prepareHeadersForAPICall({ getState, headers });
		}
	}),
	tagTypes: ['EventDetail'],
	endpoints: (builder) => ({
		fetchEventById: builder.query({
			query: (args) => {
				const { id } = args;
				return {
					url: appApiRoutes.fetchEventByIdRoute(id)
				};
			},
			providesTags: ['EventDetail']
		}),

		fetchEventAttendees: builder.query({
			query: (args) => {
				const { eventId } = args;
				const requestParams = { ...args };
				delete requestParams.eventId;

				return {
					url: appApiRoutes.fetchAttendeesByEventId(eventId),
					params: { ...requestParams }
				};
			},
			providesTags: ['EventDetail']
		}),

		captureUserInterest: builder.mutation({
			query: ({ data }) => ({
				url: appApiRoutes.captureUserInterest,
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['EventDetail']
		}),

		sendMatchRequest: builder.mutation({
			query: ({ data }) => ({
				url: appApiRoutes.sendMatchRequest,
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['EventDetail']
		})
	})
});

export const { useFetchEventByIdQuery, useFetchEventAttendeesQuery, useCaptureUserInterestMutation, useSendMatchRequestMutation } = eventDetailApiService;
