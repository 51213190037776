import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IndividualAttendee, NoResultFound, SectionLoader } from '../../../components/UI';
import ListPagination from '../../../components/UI/ListPagination';
import { genericPaginationParams } from '../../../data/constants';
import { triggerImpactOfSearchChangeToRedux } from '../../../data/utils';
import { useCancelOutgoingRequestMutation, useFetchUserOutgoingRequestQuery } from './service/outgoingRequest.service';
import { setOutGoingRequestPaginationParams } from './service/outgoingRequest.slice';

const OutgoingTab = () => {
	const outGoingRequestPaginationParams = useSelector((state) => state.outgoingRequestsSlice.listing.paginationParams);
	const navigate = useNavigate();
	const { isLoading } = useFetchUserOutgoingRequestQuery(
		{
			...outGoingRequestPaginationParams
		},
		{
			refetchOnMountOrArgChange: true
		}
	);

	const outgoingRequests = useSelector((state) => state.outgoingRequestsSlice.requests);
	const [searchString, setSearchString] = useState('');
	const [cancelRequest] = useCancelOutgoingRequestMutation();

	const handleEventsSearch = (e) => {
		setSearchString(e.target.value);
		triggerImpactOfSearchChangeToRedux({
			payload: genericPaginationParams,
			value: e.target.value,
			dispatchEvent: setOutGoingRequestPaginationParams
		});
	};

	const cancelRequestHandler = async ({ userId, eventId }) => {
		await cancelRequest({
			userId,
			eventId
		});
	};

	return (
		<div>
			{(searchString === null || searchString === '') && !isLoading && outgoingRequests?.length === 0 ? null : (
				<Stack className="flex items-stretch justify-center mb-3" spacing={2}>
					<TextField value={searchString} onChange={handleEventsSearch} placeholder="Search Outgoing Requests" variant="outlined" type="search" />
				</Stack>
			)}

			{isLoading ? (
				<SectionLoader open={isLoading} />
			) : !isLoading && outgoingRequests.length === 0 ? (
				<div className="xl:-mb-[32px]">
					<NoResultFound
						showActionButton
						buttonClickHandler={() => {
							navigate('/events');
						}}
						message={
							<p>
								Looks like you do not have any outgoing requests at the moment.
								<br />
								Go ahead and send a new match request from events you have RSVP'ed to.
							</p>
						}
					/>
				</div>
			) : (
				<>
					<div className="mt-6">
						{outgoingRequests?.map((attendee) => (
							<IndividualAttendee
								onActionButtonClick={() =>
									cancelRequestHandler({
										eventId: attendee.eventId,
										userId: attendee.id
									})
								}
								addBorderBottom
								attendee={attendee}
								key={attendee.id}
								actionButtonText="Cancel"
								addEventname
							/>
						))}
					</div>
					<Stack className="pt-4 flex items-center justify-center" spacing={2}>
						<ListPagination dispatchPaginationReducer={setOutGoingRequestPaginationParams} totalRowCount={10} pageSize={10} />
					</Stack>
				</>
			)}
		</div>
	);
};

export default OutgoingTab;
