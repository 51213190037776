import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { incomingRequestApiService } from './incomingRequest.service';

const initialState = {
	listing: {
		paginationParams: {
			offset: 0,
			limit: 10,
			searchString: ''
		}
	},
	requests: [],
	totalRequestsCount: 0
};

const incomingRequestsSlice = createSlice({
	name: 'incomingRequestsSlice',
	initialState,
	reducers: {
		resetOutGoingRequest: () => cloneDeep(initialState),
		setIncomingRequestPaginationParams: (state, action) => {
			state.listing.paginationParams = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(incomingRequestApiService.endpoints.fetchUserIncomingRequest.matchFulfilled, (state, { payload }) => {
			state.requests = payload.rows;
			state.totalRequestsCount = payload.count;
		});
	}
});

export default incomingRequestsSlice.reducer;

export const { setIncomingRequestPaginationParams, resetOutGoingRequest } = incomingRequestsSlice.actions;
