import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { eventsApiService } from './events.service';

const initialState = {
	listing: {
		paginationParams: {
			offset: 0,
			limit: 10,
			searchString: '',
			currentPage: 1
		},
		events: [],
		totalEventCount: 0
	}
};

const eventsSlice = createSlice({
	name: 'eventsSlice',
	initialState,
	reducers: {
		reset: () => cloneDeep(initialState),
		setEventListPaginationParams: (state, action) => {
			state.listing.paginationParams = action.payload;
			state.listing.currentPage = action.payload.currentPage;
		}
	},

	extraReducers: (builder) => {
		builder.addMatcher(eventsApiService.endpoints.fetchEvents.matchFulfilled, (state, { payload }) => {
			state.listing.events = payload?.rows;
			state.listing.totalEventCount = payload.count;
		});
	}
});

export default eventsSlice.reducer;

export const { reset, setEventListPaginationParams } = eventsSlice.actions;
