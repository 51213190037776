import { jwtDecode } from 'jwt-decode';
import _ from 'lodash';
import { setToken, setTokenExpired, setUser } from '../service/appAuth.slice';
import store from '../store';

export const triggerImpactOfSearchChangeToRedux = _.debounce(({ payload, value, dispatchEvent }) => {
	if (value?.trim() !== '') {
		store.dispatch(
			dispatchEvent({
				...payload,
				searchString: value?.trim()
			})
		);
	} else {
		store.dispatch(
			dispatchEvent({
				...payload
			})
		);
	}
}, 200);

export const prepareHeadersForAPICall = ({ headers, getState }) => {
	const token = getState().appAuth.token;
	const decodedToken = jwtDecode(token);
	const dateNow = new Date();
	const tokenExpired = decodedToken.exp < dateNow.getTime() / 1000;
	if (token && !tokenExpired) {
		headers.set('access-token', `Bearer ${token}`);
	} else {
		store.dispatch(setTokenExpired(true));
		store.dispatch(setUser(null));
		store.dispatch(setToken(null));
		localStorage.removeItem('currentLoggedInUser');
		localStorage.removeItem('appToken');
	}

	return headers;
};
