import { Avatar } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { AppDefaultLayout } from '../../components/Layout';
import UserProfile from './UserProfile';

const UserProfileHeader = () => {
	const TITLE = 'Profile';
	const user = useSelector((state) => state.appAuth.user);

	return (
		<>
			<Helmet>
				<title>{TITLE}</title>
			</Helmet>
			<AppDefaultLayout>
				<div className="bg-[#60acc6]">
					<div className="flex md:items-center md:justify-center w-full h-min md:h-[100px] container mx-auto bg-cover bg-no-repeat p-4 sm:p-8 md:p-0">
						<div className="md:w-[660px] text-white">
							<div className="flex items-center space-x-5">
								<Avatar loading="lazy" alt="" src={user?.avatarUrl} className="border border-white h-[50px] w-[50px] rounded-full">
									{user?.fullName?.[0]}
								</Avatar>
								<div className="space-y-2">
									<p className="text-3xl font-normal">{user?.fullName}</p>

									{(user?.cityOfResidence || user?.gender) && (
										<div className="flex items-center font-normal text-[14px]">
											{user?.gender && <span>{user?.gender}</span>}
											{user?.cityOfResidence && <span>{user?.cityOfResidence}</span>}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<UserProfile />
			</AppDefaultLayout>
		</>
	);
};

export default UserProfileHeader;
