import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { AppDefaultLayout } from '../../components/Layout';
import EventListingPage from './EventListingPage';
import { useFetchEventsQuery } from './service/events.service';

const EventList = () => {
	const eventsPaginationParams = useSelector((state) => state.eventsSlice.listing.paginationParams);
	const TITLE = 'Events';
	const { isLoading } = useFetchEventsQuery(
		{
			...eventsPaginationParams
		},
		{
			refetchOnMountOrArgChange: true
		}
	);

	return (
		<>
			<Helmet>
				<title>{TITLE}</title>
			</Helmet>
			<AppDefaultLayout>
				<EventListingPage isLoading={isLoading} />
			</AppDefaultLayout>
		</>
	);
};

export default EventList;
