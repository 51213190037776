import { createSlice } from '@reduxjs/toolkit';
import { userApiService } from './appAuth.service';

export const appSnackbarSlice = createSlice({
	name: 'appSnackBar',
	initialState: {
		openSnackBar: false,
		snackBarMessage: '',
		anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
	},
	reducers: {
		setOpenSnackBar: (state, action) => {
			state.openSnackBar = action.payload;
			state.anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
		},
		setSnackBarMessage: (state, action) => {
			state.snackBarMessage = action.payload;
		},
		closeTheSnackBar: (state) => {
			state.openSnackBar = false;
			state.snackBarMessage = '';
			state.anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
		},
		openTheSnackBar: (state, action) => {
			state.openSnackBar = !!action?.payload?.message;
			state.snackBarMessage = action?.payload?.message ?? '';
			state.anchorOrigin = action?.payload?.anchorOrigin ?? { vertical: 'bottom', horizontal: 'right' };
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(userApiService.endpoints.updateUser.matchFulfilled, (state, action) => {
			state.openSnackBar = true;
			state.snackBarMessage = 'Profile updated successfully.';
		});
	}
});

export const { setOpenSnackBar, setSnackBarMessage, closeTheSnackBar, openTheSnackBar } = appSnackbarSlice.actions;

export default appSnackbarSlice.reducer;
