import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { eventDetailApiService } from './eventDetail.service';

const initialState = {
	eventDetail: {},
	eventAttendees: {
		listing: {
			paginationParams: {
				offset: 0,
				limit: 10,
				searchString: ''
			},
			attendees: [],
			totalAttendeesCount: 0
		}
	}
};

const eventDetailSlice = createSlice({
	name: 'eventDetailSlice',
	initialState,
	reducers: {
		resetEventDetailSlice: () => cloneDeep(initialState),
		setAttendees: (state, action) => {
			state.eventAttendees = action.payload;
		},

		setAttendeesPaginationParams: (state, action) => {
			state.eventAttendees.listing.paginationParams = action.payload;
		}
	},

	extraReducers: (builder) => {
		builder.addMatcher(eventDetailApiService.endpoints.fetchEventById.matchFulfilled, (state, { payload }) => {
			state.eventDetail = payload.data;
		});
		builder.addMatcher(eventDetailApiService.endpoints.fetchEventAttendees.matchFulfilled, (state, action) => {
			state.eventAttendees.listing.attendees = action.payload.rows;
			state.eventAttendees.listing.totalAttendeesCount = action.payload.count;
		});
	}
});

export default eventDetailSlice.reducer;

export const { resetEventDetailSlice, setAttendees, setAttendeesPaginationParams } = eventDetailSlice.actions;
