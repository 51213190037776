import { styled } from '@mui/system';
import { Chat, MessageList } from '@pubnub/react-chat-components';
import moment from 'moment';
import { usePubNub } from 'pubnub-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { CustomMessageInput, CustomPubNubMessage } from '../../components/UI';

const ChatViewContainer = styled('div')(({ theme }) => ({
	'& .pn-msg-list': {
		background: 'white',
		borderRadius: '10px 10px 0px 0px'
	},

	'& .pn-msg-input': {
		background: 'white',
		borderRadius: '0px 0px 10px 10px',
		borderTop: '1px solid #8B98A4	'
	},
	'& .pn-msg-input__textarea': {
		background: 'white'
	}
}));
const ChatView = () => {
	const user = useSelector((state) => state.appAuth.user);
	const [messageVal, setMessageVal] = useState('');
	const { userId: receiverUserId } = useParams();
	const pubnub = usePubNub();
	const loggedInUserId = user.id;
	const currentChannel = loggedInUserId > receiverUserId ? `${loggedInUserId}_${receiverUserId}` : `${receiverUserId}_${loggedInUserId}`;

	const onSend = async () => {
		try {
			if (!messageVal.trim()) return;
			const idealMessagePayload = {
				id: v4(),
				text: messageVal,
				type: 'default',
				createdAt: moment(new Date()).toISOString(),
				sender: {
					deviceType: 'Web',
					platformVersion: process.env.REACT_APP_VERSION || '',
					id: `USER-${v4()}`,
					name: user.fullName,
					profileUrl: user.avatarUrl
				}
			};
			await pubnub.publish({
				message: idealMessagePayload,
				channel: currentChannel
			});
		} catch (error) {
			console.log('error', error);
		} finally {
			setMessageVal('');
		}
	};

	return (
		<div className="md:bg-[#F2F2F2] flex items-center justify-center p-4 md:p-0 bg_container">
			<ChatViewContainer className="w-full md:w-5/12 p-4 md:p-8 overflow-hidden drop-shadow-xl">
				<div className="pb-14 h-[600px] bg-white relative overflow-hidden pubNub_message_container">
					<Chat {...{ currentChannel }}>
						<MessageList
							fetchMessages={100}
							onScroll={function noRefCheck() {}}
							messageRenderer={(props) => {
								return props?.user && <CustomPubNubMessage {...props} />;
							}}
						/>
					</Chat>
					<CustomMessageInput setMessageVal={setMessageVal} messageVal={messageVal} onSend={onSend} />
				</div>
			</ChatViewContainer>
		</div>
	);
};

export default ChatView;
