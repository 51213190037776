import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { appApiRoutes } from '../../../../data/appApiRoutes';
import { prepareHeadersForAPICall } from '../../../../data/utils';

export const myMatchesApiService = createApi({
	reducerPath: 'myMatchesApiService',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BACKEND_URL,
		prepareHeaders: (headers, { getState }) => {
			prepareHeadersForAPICall({ getState, headers });
		}
	}),
	tagTypes: ['MyMatches'],
	endpoints: (builder) => ({
		getMyMatches: builder.query({
			query: (args) => {
				return { url: appApiRoutes.getMatchesOfUser, params: { ...args } };
			}
		})
	})
});

export const { useGetMyMatchesQuery } = myMatchesApiService;
