import React from 'react';
import { Helmet } from 'react-helmet';
import { AppDefaultLayout } from '../../components/Layout';
import HomePage from './HomePage';

const TITLE = 'Home';

const Home = () => {
	return (
		<>
			<Helmet>
				<title>{TITLE}</title>
			</Helmet>
			<AppDefaultLayout>
				<HomePage />
			</AppDefaultLayout>
		</>
	);
};

export default Home;
