import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { appApiRoutes } from '../../../../data/appApiRoutes';
import { prepareHeadersForAPICall } from '../../../../data/utils';

export const outGoingRequestApiService = createApi({
	reducerPath: 'outGoingRequestApiService',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BACKEND_URL,
		prepareHeaders: (headers, { getState }) => {
			prepareHeadersForAPICall({ getState, headers });
		}
	}),
	tagTypes: ['OutgoingRequests'],
	endpoints: (builder) => ({
		fetchUserOutgoingRequest: builder.query({
			query: (args) => {
				return {
					url: appApiRoutes.fetchOutGoingRequests,
					params: {
						...args
					}
				};
			},
			providesTags: ['OutgoingRequests']
		}),
		cancelOutgoingRequest: builder.mutation({
			query: (data) => {
				return {
					method: 'DELETE',
					url: appApiRoutes.cancelOutgoingRequest,
					params: { ...data }
				};
			},
			invalidatesTags: ['OutgoingRequests']
		})
	})
});

export const { useFetchUserOutgoingRequestQuery, useCancelOutgoingRequestMutation } = outGoingRequestApiService;
