import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { appApiRoutes } from '../../../../data/appApiRoutes';
import { prepareHeadersForAPICall } from '../../../../data/utils';

export const incomingRequestApiService = createApi({
	reducerPath: 'incomingRequestApiService',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BACKEND_URL,
		prepareHeaders: (headers, { getState }) => {
			prepareHeadersForAPICall({ getState, headers });
		}
	}),
	tagTypes: ['IncomingRequests'],
	endpoints: (builder) => ({
		acceptOrRejectMatchRequest: builder.mutation({
			query: ({ data }) => ({
				url: appApiRoutes.acceptOrRejectMatchRequest,
				method: 'PATCH',
				body: data
			}),
			invalidatesTags: ['IncomingRequests']
		}),

		fetchUserIncomingRequest: builder.query({
			query: (args) => {
				return {
					url: appApiRoutes.fetchIncomingRequests,
					params: {
						...args
					}
				};
			},
			providesTags: ['IncomingRequests']
		})
	})
});

export const { useFetchUserIncomingRequestQuery, useAcceptOrRejectMatchRequestMutation } = incomingRequestApiService;
