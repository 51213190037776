import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { appApiRoutes } from '../../../data/appApiRoutes';

export const eventsApiService = createApi({
	reducerPath: 'eventsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BACKEND_URL}`
	}),

	tagTypes: ['Events'],
	endpoints: (builder) => ({
		fetchEvents: builder.query({
			query: (args) => {
				return {
					url: appApiRoutes.fetchEvents,
					params: { ...args }
				};
			}
		})
	})
});

export const { useFetchEventsQuery } = eventsApiService;
