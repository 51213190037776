import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Popover } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import appLogo from '../../../assets/branding/buddy_findr_logo.svg';
import appInternalRoutes from '../../../data/appInternalRoutes';
import { setToken, setUser } from '../../../service/appAuth.slice';

const Header = ({ setTriggeredLogin = () => {} }) => {
	const [isOpen, setIsOpen] = useState(false);
	let userAgentString = navigator.userAgent;

	let safariAgent = userAgentString.indexOf('Safari') > -1;
	let chromeAgent = userAgentString.indexOf('Chrome') > -1;

	const navigate = useNavigate();
	const location = useLocation();

	const user = useSelector((state) => state.appAuth.user);

	const getNavLinkClass = (path) => {
		return location.pathname === path ? 'text-[#009afd] text-lg font-normal' : 'text-[#d87187] text-lg font-normal';
	};

	if (chromeAgent && safariAgent) safariAgent = false;

	var isSafari = window.safari;

	return (
		<header className="sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-4 container mx-auto uppercase">
			<div className="flex items-center justify-between px-4 py-3 sm:p-0">
				<img loading="lazy" onClick={() => navigate(appInternalRoutes.HOME_ROUTE)} src={appLogo} alt="appLogo" className="cursor-pointer h-[40px] xl:h-[70px]" />
				<div className="sm:hidden">
					<button type="button" className="text-gray-500">
						<div className="flex items-center justify-between">
							{user && <UserInfoAvatar user={user} />}
							{!user && isSafari && (
								<span
									onClick={() => {
										setTriggeredLogin(true);
									}}
									className="text-black text-lg font-normal cursor-pointer flex items-center justify-center space-x-2">
									<AccountCircleIcon sx={{ color: '#d87187', fill: '#d87187' }} />
									<span className="text-[#d87187]">Login</span> <span> | </span> <span className="text-[#009afd]">Signup</span>
								</span>
							)}

							{!isOpen ? (
								<MenuIcon
									onClick={() => setIsOpen(!isOpen)}
									sx={{
										marginLeft: '8px'
									}}
								/>
							) : (
								<CloseIcon
									onClick={() => setIsOpen(!isOpen)}
									sx={{
										marginLeft: '8px'
									}}
								/>
							)}
						</div>
					</button>
				</div>
			</div>
			<nav className={`sm:block ${isOpen ? 'block' : 'hidden'} text-center py-3 md:py-0`}>
				<ul className="sm:flex sm:space-x-16 space-y-3 md:space-y-0 items-center">
					<li>
						<NavLink to={appInternalRoutes.HOME_ROUTE} className={getNavLinkClass(appInternalRoutes?.HOME_ROUTE)}>
							Home
						</NavLink>
					</li>
					<li>
						<NavLink to={appInternalRoutes?.EVENTS_LISTING} className={getNavLinkClass(appInternalRoutes?.EVENTS_LISTING)}>
							Events
						</NavLink>
					</li>
					{user && (
						<li>
							<NavLink
								to={`/profile?${createSearchParams({
									selectedTab: 'my-matches'
								})}`}
								className={getNavLinkClass(appInternalRoutes?.LOGIN_ROUTE)}>
								Connections
							</NavLink>
						</li>
					)}
					{user && (
						<li>
							<NavLink to="/profile" className={getNavLinkClass(appInternalRoutes?.USER_PROFILE)}>
								Profile
							</NavLink>
						</li>
					)}
					<li>
						{!user ? (
							<span
								onClick={() => {
									setTriggeredLogin(true);
								}}
								className="text-black text-lg font-normal cursor-pointer flex items-center justify-center space-x-2">
								<AccountCircleIcon sx={{ color: '#d87187', fill: '#d87187' }} />
								<span className="text-[#d87187]">Login</span> <span> | </span> <span className="text-[#009afd]">Signup</span>
							</span>
						) : (
							<>{!isOpen && <UserInfoAvatar user={user} />}</>
						)}
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default Header;

const UserInfoAvatar = ({ user }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const navigate = useNavigate();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const dispatch = useDispatch();

	const open = Boolean(anchorEl);

	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}>
				<button
					onClick={() => {
						dispatch(setToken(null));
						dispatch(setUser(null));
						localStorage.removeItem('currentLoggedInUser');
						localStorage.removeItem('appToken');
						navigate(appInternalRoutes.HOME_ROUTE);
					}}
					style={{ padding: 8 }}>
					Logout
				</button>
			</Popover>
			<Avatar onClick={handleClick} src={user?.avatarUrl} />
		</>
	);
};
