import { Pagination } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

const ListPagination = ({ totalRowCount, pageSize, dispatchPaginationReducer, currentPage = 1, affectedSlice = {} }) => {
	const totalPages = Math.ceil(totalRowCount / pageSize);

	const dispatch = useDispatch();

	const handlePageChange = (event, value) => {
		const offset = (value - 1) * pageSize;
		dispatch(
			dispatchPaginationReducer({
				...affectedSlice.paginationParams,
				offset,
				limit: pageSize,
				currentPage: value
			})
		);
	};

	return <Pagination page={currentPage} onChange={handlePageChange} count={totalPages} shape="rounded" color="primary" variant="combined" showFirstButton showLastButton />;
};

export default ListPagination;
