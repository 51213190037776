import { createSlice } from '@reduxjs/toolkit';
import { myEventsApiService } from './myEvent.service';

const initialState = {
	listing: {
		paginationParams: {
			offset: 0,
			limit: 10,
			searchString: ''
		},
		events: [],
		totalEventCount: 0
	}
};

export const myEventsSlice = createSlice({
	name: 'myEventsSlice',
	initialState,
	reducers: {
		setMyEventsPaginationParams: (state, action) => {
			state.listing.paginationParams = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(myEventsApiService.endpoints.fetchUserEnrolledEvents.matchFulfilled, (state, { payload }) => {
			state.listing.events = payload.rows;
		});
	}
});

export default myEventsSlice.reducer;

export const { setMyEventsPaginationParams } = myEventsSlice.actions;
