import React from 'react';
import illustration from '../../assets/images/connect_with_people_illustration.svg';

const ConnectWithPeople = () => {
	return (
		<div className="container mx-auto xl:h-[60vh] flex items-center justify-center p-4 xl:p-0">
			<div className="flex items-center justify-center flex-col xl:flex-row gap-4">
				<div className="">
					<div className="flex items-start justify-center max-w-[400px] flex-col mt-[40px] xl:mt-0">
						<p className="text-[28px] xl:text-[40px] font-[700] text-[#5ca2d4]">Connect with people</p>
						<p>
							Feeling lost in the Windy City? BuddyFindr is your go-to platform for meeting new friends and experiencing Chicago like never before. Whether you're looking to attend events, explore the
							city, or just hang out, BuddyFindr connects you with people who share your interests. Don't navigate the city alone—make connections that last a lifetime with{' '}
							<span className="font-[700]"> BuddyFindr!</span>
						</p>
					</div>
				</div>

				<img loading="lazy" alt="" src={illustration} className="xl:max-w-[50%]" />
			</div>
		</div>
	);
};

export default ConnectWithPeople;
