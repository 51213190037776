import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { React } from 'react';

const DeleteUserConfirmationDialog = ({ open = false, handleClose, handleConfirm }) => {
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>
				<Typography variant="h6">Are you sure?</Typography>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">You are about to reject the incoming request. This action cannot be undone.</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="outlined">
					Cancel
				</Button>
				<Button
					onClick={() => {
						handleConfirm();
						handleClose();
					}}
					variant="contained"
					color="info">
					Reject Request
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteUserConfirmationDialog;
