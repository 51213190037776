import React from 'react';
import AboutUsIllustration from '../../assets/images/about_us_illustrator.svg';

const AboutUs = () => {
	return (
		<div className="flex items-start justify-between py-[40px] xl:py-[60px] gap-12 flex-col xl:flex-row">
			<div className="order-2 xl:order-1">
				<p className="text-[#5ca2d4] font-[600] text-[32px]">About Us</p>
				<p className="text-[18px] tracking-wider font-[400]">
					BuddyFindr was born out of a simple, yet profound need: the need for connection. When one of our founders moved to Chicago, she realized how challenging it was to find friends in a new city.
					After signing up for an event but having no one to attend with, the idea for BuddyFindr was sparked. We are more than just an app; we're a community dedicated to helping you find companions
					for events, outings, and adventures. With a focus on safety, socializing, and shared experiences, BuddyFindr aims to make every event a new opportunity to connect. Join us, and never feel
					alone in the crowd again.{' '}
				</p>
				{/* 
				<button
					onClick={(e) => {
						e.stopPropagation();
					}}
					className="bg-[#f3d24e] p-1 mt-4 text-lg font-[700]">
					Learn More
				</button> */}
			</div>
			<img src={AboutUsIllustration} alt="" loading="lazy" className="xl:max-w-[40%] order-2" />
		</div>
	);
};

export default AboutUs;
