import React from 'react';
import homepageBannerIllustration from '../../assets/images/home_page_banner_illustration.svg';
import { SearchBar } from '../../components/UI';

const Banner = () => {
	return (
		<div className="bg-[#eaeaea]">
			<div className="container py-[40px] mx-auto flex items-center justify-center flex-col">
				<div className="flex items-center xl:items-start  justify-center flex-col xl:flex-row gap-4 xl:gap-0">
					<div className="flex items-start h-[80%]">
						<div>
							<span className="block leading-none text-[40px] lg:text-[60px] font-[700] text-[#5ca2d4]">
								FIND YOUR <br /> EVENT BUDDY
							</span>
							<span className="text=[18px] xl:text-[28px] font-[400] pt-[20px] block text-[#f48888] leading-none">
								Discover The Most Happening <br /> Events Around You
							</span>
						</div>
					</div>

					<div className="flex items-end justify-end max-w-[50%]">
						<img loading="lazy" src={homepageBannerIllustration} alt="" className="" />
					</div>
				</div>

				<div className="flex md:items-center md:justify-center w-full h-min bg-cover bg-no-repeat p-4 sm:p-8 md:p-0">
					<SearchBar />
				</div>
			</div>
		</div>
	);
};

export default Banner;
