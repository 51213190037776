import { Snackbar } from '@material-ui/core';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import './assets/fonts.css';

import AppRoutes from './Routes';
import { closeTheSnackBar } from './service/appSnackBarSlice';

function App() {
	const snackBarState = useSelector((state) => state.appSnackBar);
	const { openSnackBar, snackBarMessage } = snackBarState;
	const dispatch = useDispatch();

	return (
		<>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
				<AppRoutes />
			</GoogleOAuthProvider>

			<Snackbar anchorOrigin={snackBarState.anchorOrigin} open={openSnackBar} onClose={() => dispatch(closeTheSnackBar(false))} autoHideDuration={6000} message={snackBarMessage} />
		</>
	);
}

export default App;
