import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { TabPanel } from '../../components/UI';
import appInternalRoutes from '../../data/appInternalRoutes';
import IncomingTab from './Incoming/IncomingTab';
import MyEventsTab from './MyEvents/MyEventsTab';
import MyMatchesTab from './MyMatches/MyMatchesTab';
import OutgoingTab from './Outgoing/OutgoingTab';
import ProfileTab from './Profile/ProfileTab';
import './user_profile.css';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const labelStyles = {
	textTransform: 'none',
	'& .MuiTab-wrapper': {
		textTransform: 'capitalize'
	}
};

const useStyles = makeStyles({
	tabs: {
		'& .MuiTabs-indicator': {
			backgroundColor: '#d87187'
		},
		'& .MuiTab-root.Mui-selected': {
			color: '#d87187'
		}
	}
});

const UserProfile = () => {
	const [searchParams] = useSearchParams();
	const classes = useStyles();

	const getDefaultSelectedTab = (searchParams) => {
		switch (searchParams.get('selectedTab')) {
			case 'profile':
				return 0;
			case 'my-matches':
				return 1;
			case 'my-events':
				return 2;
			case 'incoming':
				return 3;
			case 'outgoing':
				return 4;
			default:
				return 0;
		}
	};

	const navigate = useNavigate();
	const [value, setValue] = useState(getDefaultSelectedTab(searchParams));

	const handleChange = (event, newValue) => {
		setValue(newValue);

		switch (newValue) {
			case 0:
				navigate({
					pathname: appInternalRoutes.USER_PROFILE,
					search: `?${createSearchParams({
						selectedTab: 'profile'
					})}`
				});
				break;
			case 1:
				navigate({
					pathname: appInternalRoutes.USER_PROFILE,
					search: `?${createSearchParams({
						selectedTab: 'my-matches'
					})}`
				});
				break;
			case 2:
				navigate({
					pathname: appInternalRoutes.USER_PROFILE,
					search: `?${createSearchParams({
						selectedTab: 'my-events'
					})}`
				});
				break;
			case 3:
				navigate({
					pathname: appInternalRoutes.USER_PROFILE,
					search: `?${createSearchParams({
						selectedTab: 'incoming'
					})}`
				});
				break;
			case 4:
				navigate({
					pathname: appInternalRoutes.USER_PROFILE,
					search: `?${createSearchParams({
						selectedTab: 'outgoing'
					})}`
				});
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		setValue(getDefaultSelectedTab(searchParams));
	}, [searchParams]);

	return (
		<div>
			<div className="bg_container h-fit md:h-[82vh] flex items-start justify-center">
				<div className="container mx-auto flex justify-center md:p-16 m-7 max-h-full">
					<div className="w-full md:w-[660px] bg-white rounded-xl drop-shadow-lg px-4 py-4 md:px-8 md:py-8 max-h-full overflow-y-scroll styled_scroll_bar">
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs
								className={classes.tabs}
								TabIndicatorProps={{
									style: {
										color: '#D97D54',
										backgroundColor: '#D97D54'
									}
								}}
								variant="scrollable"
								scrollButtons="auto"
								value={value}
								onChange={handleChange}
								aria-label="basic tabs example">
								<Tab sx={{ ...labelStyles }} label="Profile" {...a11yProps(0)} />
								<Tab sx={{ ...labelStyles }} label="My Matches" {...a11yProps(1)} />
								<Tab sx={{ ...labelStyles }} label="My Events" {...a11yProps(2)} />
								<Tab sx={{ ...labelStyles }} label="Incoming" {...a11yProps(3)} />
								<Tab sx={{ ...labelStyles }} label="Outgoing" {...a11yProps(4)} />
							</Tabs>
						</Box>

						<TabPanel value={value} index={0}>
							<ProfileTab />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<MyMatchesTab />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<MyEventsTab />
						</TabPanel>
						<TabPanel value={value} index={3}>
							<IncomingTab />
						</TabPanel>
						<TabPanel value={value} index={4}>
							<OutgoingTab />
						</TabPanel>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserProfile;
