import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import appInternalRoutes from './data/appInternalRoutes';
import { Chat, EventDetailPage, EventListing, Home, UserProfile } from './features';

const AppRoutes = () => {
	return (
		<Router>
			<Routes>
				<Route path={appInternalRoutes.HOME_ROUTE} element={<Home />} />
				{/* <Route path={appInternalRoutes.LOGIN_ROUTE} element={<LoginOrSignup />} /> */}
				{/* <Route path={appInternalRoutes.SIGNUP_ROUTE} element={<LoginOrSignup isSignUpWorkFlow />} /> */}
				<Route path={appInternalRoutes.EVENTS_LISTING} element={<EventListing />} />
				<Route path={`${appInternalRoutes.EVENTS_LISTING}/:id`} element={<EventDetailPage />} />
				<Route path={`${appInternalRoutes.USER_PROFILE}`} element={<UserProfile />} />
				<Route path={`${appInternalRoutes.CHAT_ROUTE}/:userId`} element={<Chat />} />
				{/* <Route path={`${appInternalRoutes.CONNECTIONS_ROUTE}`} element={<Connections />} /> */}
			</Routes>
		</Router>
	);
};

export default AppRoutes;
