import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import React from 'react';

const CustomMessageInput = ({ onSend = () => {}, messageVal = '', setMessageVal = () => {} }) => {
	return (
		<div className="flex items-center justify-between bg-white p-4 rounded-b-xl border-t-[#8B98A4] border-t absolute bottom-0 right-0 left-0">
			<input
				value={messageVal ? messageVal : ''}
				onChange={(e) => {
					setMessageVal(e.target.value);
				}}
				onKeyPress={(e) => {
					if (e.key === 'Enter') {
						e.preventDefault(); // Prevent form submission
						onSend();
					}
				}}
				placeholder="Type message"
				className="flex-grow focus:outline-none focus:ring-0"
			/>
			<SendOutlinedIcon sx={{ color: '#3056D3' }} className="cursor-pointer" onClick={onSend} />
		</div>
	);
};

export default CustomMessageInput;
