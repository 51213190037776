import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { appApiRoutes } from '../../../../data/appApiRoutes';
import { prepareHeadersForAPICall } from '../../../../data/utils';

export const myEventsApiService = createApi({
	reducerPath: 'myEventsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BACKEND_URL}`,
		prepareHeaders: (headers, { getState }) => {
			prepareHeadersForAPICall({ getState, headers });
		}
	}),
	tagTypes: ['MyEvents'],
	endpoints: (builder) => ({
		fetchUserEnrolledEvents: builder.query({
			query: (args) => {
				return {
					url: appApiRoutes.fetchUserEnrolledEvents,
					params: { ...args }
				};
			}
		})
	})
});

export const { useFetchUserEnrolledEventsQuery } = myEventsApiService;
