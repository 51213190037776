import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import homePage_community from '../../assets/icons/homePage_community.svg';
import homePage_economic from '../../assets/icons/homePage_economic.svg';
import homePage_environmentally_friendly from '../../assets/icons/homePage_environmentally_friendly.svg';
import homePage_logistics from '../../assets/icons/homePage_logistics.svg';
import homePage_safety from '../../assets/icons/homePage_safety.svg';
import homePage_social from '../../assets/icons/homePage_social.svg';
import gettingStarted_bg from '../../assets/images/get_started.png';
import gettingStarted from '../../assets/images/get_started_illustration.svg';
import AboutUs from './AboutUs';
import Banner from './Banner';
import ConnectWithPeople from './ConnectWithPeople';
import HomePageKPICard from './HomePageKPICard';
import HowBuddyFindrWorks from './HowBuddyFindrWorks';

const HomePage = () => {
	const navigate = useNavigate();

	const homePageKpiData = [
		{
			imgSrc: homePage_social,
			id: 1,
			title: 'Social',
			subtitle:
				'Do you feel awkward attending an event alone? The app allows you to find a buddy or connect with like-minded individuals attending the same event, browse profiles, send messages, and make plans.',
			bgColor: '#f48888'
		},
		{
			bgColor: '#5ca2d4',
			id: 2,
			imgSrc: homePage_safety,
			title: 'Safety',
			subtitle: 'Concerned about attending alone? The app lets you find a friend or buddy, providing an extra sense of security, especially for unfamiliar or late-night events.'
		},
		{
			bgColor: '#f48888',
			id: 3,
			imgSrc: homePage_logistics,
			title: 'Logistics',
			subtitle: 'Coordinate with your buddy on transportation. Carpool, share rides, or meet up. Enjoy a smoother and more efficient experience together.'
		},
		{
			id: 4,
			bgColor: '#5ca2d4',
			imgSrc: homePage_economic,
			title: 'Economic',
			subtitle: 'Sharing costs with your buddy makes attending events more affordable. Coordinate transportation, split gas expenses, or share a ride. Foster partnership and shared experiences.'
		},
		{
			id: 5,
			bgColor: '#f48888',
			imgSrc: homePage_community,
			title: 'Community',
			subtitle: "Gather insights, learn from others' experiences, and engage with fellow attendees. Create a sense of belonging and involvement within the event community."
		},
		{
			id: 6,
			imgSrc: homePage_environmentally_friendly,
			bgColor: '#5ca2d4',
			title: 'Environmentally Friendly',
			subtitle:
				'Promote sustainability by encouraging carpooling and ride-sharing. Connect with users attending the same event, share a car, and reduce carbon emissions. Contribute to a greener event experience.'
		}
	];

	return (
		<>
			<Banner />
			<ConnectWithPeople />

			<div className="container mx-auto  p-4 xl:p-0">
				<HowBuddyFindrWorks />

				<Box className="relative flex items-center justify-between flex-col xl:flex-row mb-[40px] xl:mb-[60px]">
					<img loading="lazy" src={gettingStarted_bg} alt="" className="absolute inset-0 -z-10 object-cover h-full w-full object-bottom" />
					<img loading="lazy" src={gettingStarted} alt="" className="h-[180px] p-4" />
					<div className="px-4 xl:px-10 w-full xl:w-[80%]">
						<p className="text-white text-[18px] tracking-wider">Ready for new adventures? Click to find your event buddy and start exploring today!</p>
						<button
							onClick={(e) => {
								e.stopPropagation();
								navigate('/events');
							}}
							className="text-lg font-[700] bg-[#f3d24e] p-1 my-4">
							Get Started
						</button>
					</div>
				</Box>

				<div className="xl:mt-8 mb-8">
					<p className="font-semibold text-[40px] text-[#5ca2d4]">Why Buddyfindr</p>
					{/* <h1 className="font-bold text-lg">What We Offer</h1> */}
					{/* <p className="text-[#637381]">A website that connects solo explorers that want to go to a specific event.</p> */}
				</div>

				<div className="grid grid-cols-12 gap-6 md:gap-12 mb-[60px] xl:mb-[60px] items-stretch place-items-center">
					{homePageKpiData?.map((kpiItem) => (
						<div className="col-span-12 md:col-span-6 lg:col-span-4 relative h-full py-2 xl:py-0 px-2 xl:px-0" key={kpiItem.id}>
							<HomePageKPICard {...kpiItem} />
						</div>
					))}
				</div>

				<AboutUs />
			</div>
		</>
	);
};

export default HomePage;
