export const appApiRoutes = {
	registerUser: '/user/googleSignupOrLogin',
	updateUser: '/user/update',
	fetchEvents: '/event/fetchEvents',
	fetchEventByIdRoute: (id) => `/event/${id}`, // id will be added as suffix
	fetchAttendeesByEventId: (id) => `/event/${id}/attendees`,
	fetchOutGoingRequests: `/match/fetchOutgoingRequests`,
	fetchIncomingRequests: `/match/fetchIncomingRequests`,
	captureUserInterest: '/event/captureUserInterest',
	fetchUserEnrolledEvents: `/event/getUserEnrolledEvents`,
	sendMatchRequest: '/match/sendMatchRequest',
	acceptOrRejectMatchRequest: `match/acceptOrRejectMatchRequest`,
	cancelOutgoingRequest: '/match/cancelMatchRequest',
	getMatchesOfUser: '/match/getMatchesOfUser'
};

export const googleMapsApiURL = 'https://maps.googleapis.com/maps/api/geocode/json';
