import { makeStyles } from '@material-ui/core';
import { Avatar, Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

const calculateTimeDifference = (timestamp) => {
	if (!timestamp) {
		return <></>;
	}

	const startTime = moment(timestamp);
	const endTime = moment();
	const startTime1 = startTime.clone();
	const endTime1 = endTime.clone();
	const duration = moment.duration(endTime.diff(startTime));

	let displayTime = '';
	const weeks = parseInt(duration.asWeeks(), 10);
	const days = parseInt(duration.asDays(), 10);
	const hours = parseInt(duration.asHours(), 10);
	const minutes = parseInt(duration.asMinutes(), 10);

	if (hours === 0) {
		if (minutes > 0) {
			displayTime = `${minutes}m ago`;
		} else {
			displayTime = `Just now`;
		}
	} else if (hours < 24) {
		displayTime = `${hours}h ago`;
	} else {
		if (days < 7) {
			displayTime = `${days}d ago`;
		} else {
			if (weeks < 5) {
				displayTime = `${weeks}w ago`;
			} else {
				if (startTime1.year() === endTime1.year()) {
					displayTime = `${startTime.format('MMM D')}`;
				} else {
					displayTime = `${startTime.format('MMM D, YYYY')}`;
				}
			}
		}
	}
	return displayTime;
};

const useStyles = makeStyles(() => ({
	avatar: {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		border: 'none'
	},
	textMarginLeft: {
		marginLeft: '0.3rem'
	},
	name: {
		color: 'gray'
	},
	handle: {
		color: '#8B98A4'
	},
	messageText: {
		fontSize: '1rem',
		fontWeight: 400,
		marginTop: '0.5rem',
		lineHeight: '1.1rem',
		wordBreak: 'break-word',
		marginBottom: '12px'
		// border: '1px solid #F0F0F0',
	},
	time: {
		color: '#8C8C8C',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '20px'
	}
}));

const CustomPubnubMessage = ({ message: messageEnvelop, user }) => {
	const { name: userName, profileUrl } = user;
	const {
		message: { createdAt = '', text }
	} = messageEnvelop;

	const classes = useStyles();

	return (
		<>
			<Box className="pub_nub_message" display={'flex'} alignItems={'flex-start'}>
				<Avatar onClick={() => {}} src={profileUrl} />
				<Box sx={{ mx: '8px' }}>
					<Box component={'div'} sx={{ mb: '8px', cursor: 'default' }}>
						<Box display={'flex'} alignItems={'center'}>
							<Typography onClick={() => {}} sx={{ fontWeight: '600' }}>
								{userName}
							</Typography>
						</Box>
						<Typography className={classes.time}>{calculateTimeDifference(createdAt)}</Typography>
					</Box>
					<Typography className={classes.messageText}>{text}</Typography>
				</Box>
			</Box>
		</>
	);
};

export default CustomPubnubMessage;
