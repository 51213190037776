import { Stack } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { EventListCard, NoResultFound, SearchBar, SectionLoader } from '../../components/UI';
import ListPagination from '../../components/UI/ListPagination';
import { useCaptureUserInterestMutation } from '../EventDetail/service/eventDetail.service';
import { setEventListPaginationParams } from './service/events.slice';

const EventListingPage = ({ isLoading }) => {
	const events = useSelector((state) => state.eventsSlice.listing.events);
	const affectedSliceForPagination = useSelector((state) => state.eventsSlice.listing);
	const totalEventCount = useSelector((state) => state.eventsSlice.listing.totalEventCount);
	const currentPage = useSelector((state) => state.eventsSlice.listing.paginationParams.currentPage);
	const [captureUserInterest] = useCaptureUserInterestMutation();

	return (
		<>
			<div className="bg-[#e6eef4]">
				<div className="bg-[#60acc6]">
					<div className="flex items-center justify-center w-full h-min md:h-[100px] container mx-auto bg-cover bg-no-repeat p-4 sm:p-8 md:p-0">
						<div className="w-[100%] text-white">
							<div className="flex items-center justify-center">
								<SearchBar />
							</div>
						</div>
					</div>
				</div>

				<div className="min-h-screen container md:mx-auto flex justify-center">
					<div className="w-[660px] p-2 md:p-8 md:rounded-3xl drop-shadow-lg">
						{isLoading ? (
							<SectionLoader open={isLoading} />
						) : events?.length === 0 && !isLoading ? (
							<div className="h-[90%] w-full flex items-center justify-center bg-white">
								<NoResultFound
									message={
										<p>
											Whoa, we hit a wall here!
											<br />
											We couldn't find the event you are looking for.
										</p>
									}
								/>
							</div>
						) : (
							<>
								{events?.map((event) => (
									<div key={event.id} className="mb-4 md:mb-6 bg-white p-4 rounded-2xl">
										<EventListCard captureUserInterest={captureUserInterest} eventDetail={{ ...event }} />
									</div>
								))}
								<Stack className="md:pt-4 flex items-center justify-center" spacing={2}>
									<ListPagination
										affectedSlice={affectedSliceForPagination}
										currentPage={currentPage}
										dispatchPaginationReducer={setEventListPaginationParams}
										totalRowCount={totalEventCount}
										pageSize={10}
									/>
								</Stack>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default EventListingPage;
