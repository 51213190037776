import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IndividualAttendee, NoResultFound, SectionLoader } from '../../../components/UI';
import ListPagination from '../../../components/UI/ListPagination';
import appInternalRoutes from '../../../data/appInternalRoutes';
import { genericPaginationParams } from '../../../data/constants';
import { triggerImpactOfSearchChangeToRedux } from '../../../data/utils';
import { useGetMyMatchesQuery } from './services/myMatches.service';
import { setMyMatchesPaginationParams } from './services/myMatches.slice';

const MyMatchesTab = () => {
	const navigate = useNavigate();
	const myMatches = useSelector((state) => state.myMatches.matches);
	const myMatchesPaginationParams = useSelector((state) => state.myMatches.listing.paginationParams);
	const [searchString, setSearchString] = useState('');

	const { isLoading } = useGetMyMatchesQuery(
		{
			...myMatchesPaginationParams
		},
		{
			refetchOnMountOrArgChange: true
		}
	);
	const handleEventsSearch = (e) => {
		setSearchString(e.target.value);
		triggerImpactOfSearchChangeToRedux({
			payload: genericPaginationParams,
			value: e.target.value,
			dispatchEvent: setMyMatchesPaginationParams
		});
	};

	return (
		<>
			{(searchString === null || searchString === '') && !isLoading && myMatches?.length === 0 ? null : (
				<Stack className="flex items-stretch justify-center mb-3" spacing={2}>
					<TextField value={searchString} onChange={handleEventsSearch} placeholder="Search Matches" variant="outlined" type="search" />
				</Stack>
			)}

			{isLoading ? (
				<SectionLoader open={isLoading} />
			) : !isLoading && myMatches.length === 0 ? (
				<div className="xl:-mb-[32px]">
					<NoResultFound
						showActionButton
						buttonClickHandler={() => {
							navigate('/events');
						}}
						message={
							<p>
								Looks like you do not have any matches at the moment.
								<br />
								Go ahead and send a match request from events you have RSVP'ed to.
								<br />
								You will see your matches once your friends have accepted your match request.
							</p>
						}
					/>
				</div>
			) : (
				<>
					<div className="mt-6">
						{myMatches?.map((attendee) => (
							<IndividualAttendee
								addBorderBottom
								attendee={attendee}
								key={attendee.id}
								actionButtonText="Chat"
								addEventname
								onActionButtonClick={() => navigate(`${appInternalRoutes.CHAT_ROUTE}/${attendee.id}?attendee=${encodeURIComponent(JSON.stringify(attendee))}`)}
							/>
						))}
					</div>
					<Stack className="pt-4 flex items-center justify-center" spacing={2}>
						<ListPagination totalRowCount={10} pageSize={10} />
					</Stack>
				</>
			)}
		</>
	);
};

export default MyMatchesTab;
