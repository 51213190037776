import { CalendarMonthOutlined } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Chip } from '@mui/material';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import appInternalRoutes from '../../data/appInternalRoutes';

const EventListCard = ({ eventDetail }) => {
	const location = useLocation();
	const classification = eventDetail?.classifications?.find((i) => i?.primary === true);
	const user = useSelector((state) => state.appAuth.user);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const zone_name = moment.tz.guess();
	const timezone = moment.tz(zone_name).zoneAbbr();
	const dispatch = useDispatch();

	const classificationSet = useMemo(() => {
		const classificationSetInitial = new Set();

		if (classification?.segment?.name && classification?.segment?.name !== 'Undefined') {
			classificationSetInitial.add(classification?.segment?.name);
		}
		if (classification?.genre?.name && classification?.genre?.name !== 'Undefined') {
			classificationSetInitial.add(classification?.genre?.name);
		}
		if (classification?.subGenre?.name && classification?.subGenre?.name !== 'Undefined') {
			classificationSetInitial.add(classification?.subGenre?.name);
		}

		return classificationSetInitial;
	}, [classification?.genre, classification?.segment, classification?.subGenre]);

	const handleEventSummaryCardClick = () => {
		if (!user) {
			setSearchParams({ triggerLogin: true, forwardPath: `${appInternalRoutes.EVENTS_LISTING}/${eventDetail?.id}` });
			localStorage.setItem('triggerLogin', true);
			localStorage.setItem('forwardPath', `${appInternalRoutes.EVENTS_LISTING}/${eventDetail?.id}`);
			dispatch({ type: `appSnackBar/openTheSnackBar`, payload: { message: 'Please click on Login or Sign up to continue.', anchorOrigin: { vertical: 'top', horizontal: 'center' } } });
		} else {
			navigate(`${appInternalRoutes.EVENTS_LISTING}/${eventDetail?.id}`);
		}
	};

	return (
		<Box onClick={handleEventSummaryCardClick} className={'cursor-pointer'}>
			<div className="w-full pb-3 md:py-4 sm:py-4">
				<div className="grid grid-cols-12 md:place-items-center gap-3">
					<div className="col-span-4 flex justify-start w-full h-full">
						<img
							loading="lazy"
							src={eventDetail?.eventImageUrl || 'https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
							alt=""
							className="h-full w-full object-cover rounded-xl"
						/>
						{/* <p className="text-center">
							<span className="text-sm font-normal">{moment(eventDetail?.eventStartDate).format('MMM').toLocaleUpperCase()}</span>
							<br />
							<span className="text-md font-semibold">{moment(eventDetail?.eventStartDate).date()}</span>
						</p> */}
					</div>
					<div className="col-span-7 justify-self-start">
						<div>
							<div className="flex flex-wrap items-center justify-start -ml-[5px] text-[#717171] font-normal gap-[8px] text-[14px]">
								<div className="flex items-center">
									<CalendarMonthOutlined style={{ height: '16px', padding: '0px', margin: '0px' }} />
									<span>{moment(eventDetail?.eventStartDate)?.format('ddd, MMM D')}</span>
								</div>
								<div className="mx-2 h-1 w-1 bg-[#7F7F7F] rounded-full hidden xl:block" />
								<span>
									{moment(eventDetail?.eventStartDate)?.format('hh:mm A')} {timezone}
								</span>
								<div className="mx-2 h-1 w-1 bg-[#7F7F7F] rounded-full hidden xl:block" />
								<div className="flex items-center">
									<LocationOnIcon style={{ height: '16px', padding: '0px', margin: '0px' }} />
									<span>{eventDetail?.address?.city}</span>
								</div>
							</div>
							<p className="mt-1 font-bold text-base md:text-lg text-[#595959] text-ellipsis line-clamp-1">{eventDetail?.eventName}</p>
							<div className="flex items-center flex-wrap py-2 gap-2">
								{Array.from(classificationSet).map((name, index) => (
									<Chip key={index} variant="outlined" label={name} sx={{ borderRadius: '4px', fontWeight: 'bold', px: '0px', py: '0px', height: '28px !important' }} />
								))}
							</div>

							<button className="text-xs bg-[#f3d24e] py-1 px-2">{location.pathname.includes('profile') ? <>INTERESTED</> : <>ATTEND</>}</button>
						</div>
					</div>
					<div className="col-span-1 flex justify-end w-full">
						<ChevronRightIcon />
					</div>
				</div>
			</div>
		</Box>
	);
};

export default EventListCard;
