import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import appInternalRoutes from '../../../data/appInternalRoutes';
import { setTokenExpired } from '../../../service/appAuth.slice';
import LoginOrSignupDialog from '../../UI/LoginOrSignup/LoginOrSignupDialog';
import Footer from './Footer';
import Header from './Header';
import './appDefaultLayout.css';

const AppDefaultLayout = ({ children }) => {
	const [triggeredLogin, setTriggeredLogin] = useState(false);
	const user = useSelector((state) => state.appAuth.user);
	const navigate = useNavigate();
	const tokenExpired = useSelector((state) => state.appAuth.tokenExpired);
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const triggerLoginModal = searchParams.get('triggerLogin') || localStorage.getItem('triggerLogin');

	useEffect(() => {
		if (!user && window.location.pathname === appInternalRoutes.USER_PROFILE) {
			navigate(appInternalRoutes.HOME_ROUTE);
		} else if (user && !user?.isUserAccountSetupComplete && window.location.pathname !== appInternalRoutes.USER_PROFILE) {
			navigate(appInternalRoutes.USER_PROFILE);
			dispatch({ type: `appSnackBar/openTheSnackBar`, payload: { message: 'Please complete profile setup to continue.', anchorOrigin: { vertical: 'top', horizontal: 'center' } } });
		}
	}, [user]);

	useEffect(() => {
		if (triggerLoginModal) {
			setTriggeredLogin(true);
		}
	}, [triggerLoginModal]);

	return (
		<>
			<Header setTriggeredLogin={setTriggeredLogin} />
			{children}
			<Footer />

			<LoginOrSignupDialog
				open={triggeredLogin || tokenExpired}
				onClose={() => {
					setTriggeredLogin(false);
					dispatch(setTokenExpired(false));
					setSearchParams();
				}}
			/>
		</>
	);
};

export default AppDefaultLayout;
