import React from 'react';
import exploreEvents from '../../assets/images/explore_events.svg';
import expressIntrest from '../../assets/images/express_intrest.svg';
import matchWithBuddies from '../../assets/images/match_with_buddies.svg';
import planTogether from '../../assets/images/plan_together.svg';

const HowBuddyFindrWorks = () => {
	return (
		<>
			<p className="font-semibold text-[40px] text-[#5ca2d4] py-6">How Buddyfindr Works</p>

			<div className="flex flex-col xl:flex-row xl:items-baseline justify-around mb-[40px] xl:mb-[80px] gap-6">
				<div className="xl:max-w-[200px] flex flex-col items-center justify-between text-center">
					<img loading="lazy" className="max-w-[120px]" src={exploreEvents} alt="" />

					<p className="font-[600] mt-[6px] text-[18px]">Explore Events</p>

					<p className="text-[14px] mt-[6px]">Find exciting events happening around Chicago</p>
				</div>
				<div className="xl:max-w-[200px] flex flex-col items-center justify-between text-center">
					<img loading="lazy" className="max-w-[120px]" src={expressIntrest} alt="" />

					<p className="font-[600] mt-[6px] text-[18px]"> Express Interest</p>

					<p className="text-[14px] mt-[6px]"> Mark the events you want to attend</p>
				</div>
				<div className="xl:max-w-[200px] flex flex-col items-center justify-between text-center">
					<img loading="lazy" className="max-w-[120px]" src={matchWithBuddies} alt="" />

					<p className="font-[600] mt-[6px] text-[18px]">Match with Buddies</p>

					<p className="text-[14px] mt-[6px]">Connect with others who share your interests</p>
				</div>
				<div className="xl:max-w-[200px] flex flex-col items-center justify-between text-center">
					<img loading="lazy" className="max-w-[120px]" src={planTogether} alt="" />

					<p className="font-[600] mt-[6px] text-[18px]">Plan Together</p>

					<p className="text-[14px] mt-[6px]">Coordinate your plans and attend events with new friends</p>
				</div>
			</div>
		</>
	);
};

export default HowBuddyFindrWorks;
