import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IndividualAttendee } from '../../components/UI';
import ListPagination from '../../components/UI/ListPagination';
import appInternalRoutes from '../../data/appInternalRoutes';
import { genericPaginationParams } from '../../data/constants';
import { triggerImpactOfSearchChangeToRedux } from '../../data/utils';
import { useSendMatchRequestMutation } from './service/eventDetail.service';
import { setAttendeesPaginationParams } from './service/eventDetail.slice';

const EventAttendeesListing = ({ eventAttendees }) => {
	const eventAttendeesCount = useSelector((state) => state.eventDetailSlice.eventAttendees.listing.totalAttendeesCount);
	const [searchString, setSearchString] = useState('');
	const [sendMatchRequest] = useSendMatchRequestMutation();
	const eventDetail = useSelector((state) => state.eventDetailSlice.eventDetail);
	const navigate = useNavigate();

	const handleEventsSearch = (e) => {
		setSearchString(e.target.value);
		triggerImpactOfSearchChangeToRedux({
			payload: genericPaginationParams,
			value: e.target.value,
			dispatchEvent: setAttendeesPaginationParams
		});
	};

	const handleActionButtonClick = async (user) => {
		const { id: userId, requestStatus: status } = user;

		if (status === 'NOT INITIATED') {
			await sendMatchRequest({ data: { userId, eventId: eventDetail?.id } });
		} else if (status === 'MATCHED') {
			navigate(`${appInternalRoutes.CHAT_ROUTE}/${userId}?attendee=${encodeURIComponent(JSON.stringify(user))}`);
		}
	};
	return (
		<div className="px-2 xl:px-0">
			<div className="flex items-center justify-between">
				<p className="text-base font-semibold my-3">Attendees</p>

				{/* <button className="bg-[#e6eef4]">Pending Requests (2)</button> */}
			</div>
			{/* <p className="font-normal text-[#637381] text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices lectus sem.</p> */}
			<div className="mt-6">
				{eventAttendeesCount > 0 && (
					<Stack className="flex items-stretch justify-center mb-3" spacing={2}>
						<TextField value={searchString} onChange={handleEventsSearch} placeholder="Search Attendees" variant="outlined" />
					</Stack>
				)}

				{eventAttendees.length === 0 ? (
					<div className="h-full w-full">
						<p className="font-semibold">{eventAttendeesCount === 0 && searchString ? 'No Results found' : 'No Attendees Found'} </p>
					</div>
				) : (
					<>
						{eventAttendees?.map((user) => (
							<IndividualAttendee
								onActionButtonClick={() => handleActionButtonClick(user)}
								addBorderBottom
								attendee={user}
								key={user.id}
								actionButtonText={user.requestStatus === 'NOT INITIATED' ? 'Match' : user.requestStatus === 'MATCHED' ? 'CHAT' : user.requestStatus}
							/>
						))}
						<Stack className="md:pt-4 flex items-center justify-center" spacing={2}>
							<ListPagination dispatchPaginationReducer={setAttendeesPaginationParams} totalRowCount={eventAttendeesCount} pageSize={10} />
						</Stack>
					</>
				)}
			</div>
		</div>
	);
};

export default EventAttendeesListing;
