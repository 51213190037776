import { Box } from '@mui/material';
import React from 'react';

const HomePageKPICard = ({ imgSrc, title, subtitle, bgColor }) => {
	return (
		<div
			className="pt-4  drop-shadow-lg bg-white m-3 h-full border-t border-t-[#F1F1F1] relative"
			style={{
				// backgroundImage: `url(${bgImg})`,
				background: bgColor,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'bottom 10% left 90%',
				backgroundSize: '75%'
			}}>
			<h4 className="font-semibold text-2xl pt-0 pb-2 text-[#fff] tracking-wider px-16">{title}</h4>
			<p className="text-[#fff] px-8">{subtitle}</p>

			<Box
				sx={{ background: bgColor, top: '-20px', left: '-20px', borderTopLeftRadius: '25px', borderBottomRightRadius: '25px', boxShadow: '1px 2px 2px white' }}
				className="absolute h-[60px] w-[70px] flex items-center justify-center w-[70px] h-[70px]">
				<img loading="lazy" src={imgSrc} alt="" className="h-[60px]" />
			</Box>
		</div>
	);
};

export default HomePageKPICard;
