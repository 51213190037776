import { CalendarMonthOutlined } from '@mui/icons-material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCaptureUserInterestMutation } from './service/eventDetail.service';

const EventOverview = ({ eventData }) => {
	const [captureUserInterest] = useCaptureUserInterestMutation();
	const eventDetails = useSelector((state) => state.eventDetailSlice.eventDetail);
	const handleInterestClick = async () => {
		await captureUserInterest({ data: { eventId: eventDetails?.id } });
	};
	const zone_name = moment.tz.guess();
	const timezone = moment.tz(zone_name).zoneAbbr();

	const classification = eventDetails?.classifications?.find((i) => i?.primary === true);

	const classificationSet = useMemo(() => {
		const classificationSetInitial = new Set();

		if (classification?.segment?.name && classification?.segment?.name !== 'Undefined') {
			classificationSetInitial.add(classification?.segment?.name);
		}
		if (classification?.genre?.name && classification?.genre?.name !== 'Undefined') {
			classificationSetInitial.add(classification?.genre?.name);
		}
		if (classification?.subGenre?.name && classification?.subGenre?.name !== 'Undefined') {
			classificationSetInitial.add(classification?.subGenre?.name);
		}

		return classificationSetInitial;
	}, [classification?.genre, classification?.segment, classification?.subGenre]);

	return (
		<div>
			{/* <p className="text-sm font-normal text-[#3056D3] pb-3">INFORMATION</p> */}

			<div className="grid grid-cols-12 gap-6">
				<img
					loading="lazy"
					className="col-span-12 xl:col-span-6 w-full"
					src={eventData?.eventImageUrl || 'https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
					alt=""
				/>

				<div className="col-span-12 xl:col-span-6">
					<iframe
						src={`https://maps.google.com/maps?q= ${eventData?.eventLatitude},${eventData?.eventLongitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
						height=""
						title="map"
						className="w-full h-[90%]"
						style={{
							border: '0px'
						}}
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					/>

					<div className="flex items-center font-normal text-[14px] whitespace-nowrap">
						<CalendarMonthOutlined style={{ height: '16px', padding: '0px', margin: '0px' }} />
						<span>{moment(eventData?.eventStartDate)?.format('ddd, MMM D')}</span>
						<div className="mx-2 h-1 w-1 bg-[#7F7F7F] rounded-full" />
						<span>
							{moment(eventData?.eventStartDate)?.format('hh:mm A')} {timezone}
						</span>
						<div className="mx-2 h-1 w-1 bg-[#7F7F7F] rounded-full" />
						<LocationOnIcon style={{ height: '16px', padding: '0px', margin: '0px' }} />
						<p>{eventData?.address?.city}</p>
					</div>
				</div>
			</div>

			<Typography className="pt-4">
				{eventData?.venueName}, <br /> {eventData?.address?.address}, {eventData?.address?.city}, {eventData?.address?.state}
			</Typography>

			<Box className="space-x-3 pt-2">
				{Array.from(classificationSet).map((name, index) => (
					<Chip key={index} variant="outlined" label={name} sx={{ borderRadius: '4px', fontWeight: 'bold', px: '0px', py: '0px', height: '28px' }} />
				))}
			</Box>

			<div className="my-5 flex md:items-center space-y-2 md:space-y-0 space-x-3 flex-row">
				<button onClick={handleInterestClick} className={`flex items-center ${eventData?.userIsInterested ? 'bg-[#f3d24e]' : 'bg-[#f3d24e]'}  p-2`}>
					<>
						{eventData?.userIsInterested ? (
							<>
								{/* <DoneOutlinedIcon sx={{ mr: '10px' }} /> */}
								INTERESTED
							</>
						) : (
							<>I am Interested</>
						)}
					</>
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();

						const params = {
							action: 'TEMPLATE',
							text: eventDetails.eventName,
							details: eventData?.eventDescription?.length > 100 ? eventData?.eventDescription?.substring(0, 100) : eventData?.eventDescription ?? '',
							dates: `${eventDetails?.eventStartDate}}`,
							location: `${eventData?.address?.address}, ${eventData?.address?.city}, ${eventData?.address?.state}` // assuming eventLatitude and eventLongitude are the properties holding the coordinates
						};

						const queryString = new URLSearchParams(params).toString();
						const googleCalendarURL = `https://calendar.google.com/calendar/render?${queryString}`;
						window.open(googleCalendarURL, '_blank');
					}}
					className="flex items-center text-[#3056D3] py-2">
					<CalendarTodayOutlinedIcon sx={{ mr: '10px' }} />
					Add to Calendar
				</button>
			</div>

			{eventData?.eventDescription && <p className="text-[#637381] text-base font-normal pb-7">{eventData?.eventDescription}</p>}

			{/* <div className="h-px w-full bg-[#7F7F7F] my-6" /> */}
		</div>
	);
};

export default EventOverview;
