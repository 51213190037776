import { createSlice } from '@reduxjs/toolkit';
import { myMatchesApiService } from './myMatches.service';

const initialState = {
	listing: {
		paginationParams: {
			offset: 0,
			limit: 10,
			searchString: ''
		}
	},
	matches: [],
	totalMatchesCount: 0
};

export const myMatchesSlice = createSlice({
	name: 'myMatchesSlice',
	initialState,
	reducers: {
		setMyMatchesPaginationParams: (state, action) => {
			state.listing.paginationParams = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(myMatchesApiService.endpoints.getMyMatches.matchFulfilled, (state, { payload }) => {
			state.matches = payload?.rows;
			state.totalMatchesCount = payload?.count;
		});
	}
});

export default myMatchesSlice.reducer;

export const { setMyMatchesPaginationParams } = myMatchesSlice.actions;
