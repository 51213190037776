import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Avatar } from '@mui/material';
import React from 'react';

const IndividualAttendee = ({
	attendee,
	actionButtonText = '',
	onActionButtonClick = () => {},
	addEventname = false,
	addBorderBottom = false,
	addUserDeletion = false,
	onDeleteButtonClick = () => {}
}) => {
	return (
		<div className={`grid grid-cols-12 gap-2 md:gap-2 py-4 ${addBorderBottom && 'border-b border-b-[#7F7F7F80]'} `}>
			<div className="col-span-2 md:col-span-1 flex items-center justify-center">
				<Avatar className="h-10 w-10 rounded-full" src={attendee?.avatarUrl} />
			</div>
			<div className="col-span-8 md:col-span-9 pl-3">
				<p className="line-clamp-1 text-ellipsis">{attendee?.fullName}</p>

				<div className="flex items-center text-[#717171] font-normal text-[14px]">
					<p>{attendee?.gender}</p>
					<div className="mx-2 h-1 w-1 bg-[#7F7F7F] rounded-full" />
					<p>{attendee?.cityOfResidence}</p>
				</div>

				{addEventname && <p>{attendee?.eventName}</p>}
			</div>
			<div className="flex items-center justify-center col-span-2">
				{addUserDeletion && (
					<>
						<DeleteOutlineOutlinedIcon onClick={onDeleteButtonClick} sx={{ color: 'red', mr: '6px' }} />
					</>
				)}
				{actionButtonText && (
					<div className="flex items-center justify-end">
						<button onClick={onActionButtonClick} className="bg-[#f3d24e] text-black px-2 py-1">
							{actionButtonText}
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default IndividualAttendee;
