import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import React from 'react';
import buddyFindrLogoLight from '../../../assets/branding/buddy_findr_logo_light.svg';
import facebookIcon from '../../../assets/icons/facebook.svg';
import instagramIcon from '../../../assets/icons/instagram.svg';
import footerIllustration from '../../../assets/images/footer_illustration.svg';

const Footer = () => {
	const year = new Date().getFullYear();
	return (
		<footer className="footer_container text-white flex py-5 md:py-0">
			<div className="container mx-auto px-4 sm:px-6 lg:px-0 h-full items-center justify-center relative">
				<img loading="lazy" src={footerIllustration} className="absolute bottom-0 right-0 h-[40%] xl:h-[80%]" alt="" />
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-12 gap-4 h-full items-start my-4">
					<div className="col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4">
						<div className="max-w-xs space-y-4">
							<img loading="lazy" className="w-[4rem] xl:w-32" src={buddyFindrLogoLight} alt="" />
							<div className="flex items-start space-y-3 flex-col">
								<a className="flex items-center space-x-2" href="mailto:info@buddyfindr.com" target="_blank" rel="noreferrer">
									<EmailOutlinedIcon />
									<span> info@buddyfindr.com</span>
								</a>
							</div>
						</div>
					</div>

					<div className="col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4">
						<div className="max-w-xs space-y-2">
							{/* <p className="mb-2 font-semibold text-lg">Company</p> */}
							<div className="space-y-4">
								{/* <p>About BuddyFindr</p> */}
								{/* <p>Contact & Support</p> */}
								<a href="https://staging.buddyfindr.com/TermsOfService.pdf" target="_blank" rel="noreferrer">
									<p className="pb-2">Terms of service</p>
								</a>
								<a href="https://staging.buddyfindr.com/Buddyfindr-Privacy-Policy-v1.0.0.pdf" target="_blank" rel="noreferrer">
									<p>Privacy Policy</p>
								</a>
							</div>
						</div>

						<div className="max-w-xs space-y-2 mt-6">
							<p className="mb-2 font-semibold text-lg">Follow Us On</p>
							<div className="space-y-4">
								<div className="flex self-start max-w-[172px] space-x-2">
									<a href="https://www.instagram.com/Buddy_findr" target="_blank" rel="noreferrer">
										<img height="30px" width="30px" loading="lazy" src={instagramIcon} alt="" />
									</a>
									<a href="https://www.facebook.com/Buddyfindr" target="_blank" rel="noreferrer">
										<img height="30px" width="30px" loading="lazy" src={facebookIcon} alt="" />
									</a>
								</div>
								<p>© {year} BuddyFindr.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
