import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import noResultsFoundIllustration from '../../assets/branding/no_results_found_illustration.svg';

const NoResultsFound = ({ message, showActionButton = false, btnText = 'Explore Events', buttonClickHandler = () => {} }) => {
	const tokenExpired = useSelector((state) => state.appAuth.tokenExpired);

	return (
		<>
			<Box className={'xl:h-[300px]'} sx={{ width: '100%', display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
				<div className="flex items-center justify-around">
					<p style={{ alignSelf: 'flex-start' }} className="py-4 text-left max-w-[50%] align-self-start">
						{tokenExpired ? (
							'Log in to see the results'
						) : message !== null ? (
							message
						) : (
							<>
								Whoa, we hit a wall here! <br /> Maybe try a different path?
							</>
						)}

						{showActionButton && (
							<span className="text-xs bg-[#f3d24e] p-1 mt-2 inline-block" role="button" onClick={buttonClickHandler}>
								{btnText}
							</span>
						)}
					</p>
					<img loading="lazy" className="max-h-[250px]" src={noResultsFoundIllustration} alt="" />
				</div>
			</Box>
		</>
	);
};

export default NoResultsFound;
