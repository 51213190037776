import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EventListCard, NoResultFound, SectionLoader } from '../../../components/UI';
import ListPagination from '../../../components/UI/ListPagination';
import { genericPaginationParams } from '../../../data/constants';
import { triggerImpactOfSearchChangeToRedux } from '../../../data/utils';
import { useCaptureUserInterestMutation } from '../../EventDetail/service/eventDetail.service';
import { useFetchUserEnrolledEventsQuery } from './services/myEvent.service';
import { setMyEventsPaginationParams } from './services/myEvents.slice';

const MyEventsTab = () => {
	const [searchString, setSearchString] = useState('');
	const eventsPaginationParams = useSelector((state) => state.myEvents.listing.paginationParams);
	const userEnrolledEvents = useSelector((state) => state.myEvents.listing.events);
	const [captureUserInterest] = useCaptureUserInterestMutation();

	const navigate = useNavigate();
	const { isLoading } = useFetchUserEnrolledEventsQuery(
		{
			...eventsPaginationParams
		},
		{
			refetchOnMountOrArgChange: true
		}
	);

	const handleEventsSearch = (e) => {
		setSearchString(e.target.value);
		triggerImpactOfSearchChangeToRedux({
			payload: genericPaginationParams,
			value: e.target.value,
			dispatchEvent: setMyEventsPaginationParams
		});
	};

	return (
		<>
			{(searchString === null || searchString === '') && !isLoading && userEnrolledEvents?.length === 0 ? null : (
				<Stack className="flex items-stretch justify-center mb-4" spacing={2}>
					<TextField value={searchString} onChange={handleEventsSearch} placeholder="Search Events" variant="outlined" type="search" />
				</Stack>
			)}

			{isLoading ? (
				<SectionLoader open={isLoading} />
			) : !isLoading && userEnrolledEvents.length === 0 ? (
				<div className="xl:-mb-[32px]">
					<NoResultFound
						showActionButton
						buttonClickHandler={() => {
							navigate('/events');
						}}
						message={
							<p>
								Looks like you haven't RSVP'ed to any events yet.
								<br />
								Go ahead and RSVP to the events you are interested in.
							</p>
						}
					/>
				</div>
			) : (
				<div>
					{userEnrolledEvents?.map((event) => (
						<EventListCard captureUserInterest={captureUserInterest} key={event.id} eventDetail={{ ...event }} />
					))}
					<Stack className="pt-4 flex items-center justify-center" spacing={2}>
						<ListPagination totalRowCount={10} pageSize={10} dispatchPaginationReducer={''} searchString={searchString} />
					</Stack>
				</div>
			)}
		</>
	);
};

export default MyEventsTab;
