import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { googleMapsApiURL } from '../../../data/appApiRoutes';
import { useUpdateUserMutation } from '../../../service/appAuth.service';

const validationSchema = yup.object({
	cityOfResidence: yup.string('Enter your city').required('Invalid Zip Code provided'),
	zipCode: yup.string('Enter your Zip Code').required('Zip Code is required'),
	gender: yup.string('Select your gender').required('Gender is required')
});

const useStyles = makeStyles({
	inputStyle: {
		'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			display: 'none'
		}
	}
});

const ProfileTab = () => {
	const classes = useStyles();
	const [updateUser] = useUpdateUserMutation();
	const user = useSelector((state) => state.appAuth.user);

	const formik = useFormik({
		initialValues: {
			cityOfResidence: user?.cityOfResidence || '',
			zipCode: user?.zipCode || '',
			gender: user?.gender || '',
			bio: user?.bio || '',
			latitude: '',
			longitude: ''
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			await updateUser({ data: values });
		}
	});

	const {
		values: { zipCode },
		setFieldValue
	} = formik;

	useEffect(() => {
		const fetchZipCodeData = async () => {
			if (zipCode) {
				const response = await axios({
					method: 'get',
					url: googleMapsApiURL,
					params: {
						key: process.env.REACT_APP_MAPS_API_KEY,
						address: zipCode,
						country: 'United States'
					}
				});
				const lat = response.data?.results?.[0]?.geometry?.location?.lat;
				const long = response.data?.results?.[0]?.geometry?.location?.lng;
				setFieldValue('cityOfResidence', response.data?.results?.[0]?.address_components[1]?.long_name);
				setFieldValue('latitude', lat);
				setFieldValue('longitude', long);
			}
		};

		fetchZipCodeData();
	}, [zipCode]);

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<div className="my-4 grid grid-cols-12 gap-4">
					<div className="col-span-6">
						<InputLabel id="zipCode-label">
							Zip Code <span className="text-[#FF4D4F]">*</span>
						</InputLabel>

						<TextField
							style={{ marginTop: '12px' }}
							variant="outlined"
							fullWidth
							id="zipCode"
							type="number"
							name="zipCode"
							InputProps={{
								classes: { input: classes.inputStyle }
							}}
							value={formik.values.zipCode}
							onChange={(e) => {
								if (e.target.value.length > 10) {
									return;
								}
								formik.handleChange(e);
							}}
							error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
							helperText={formik.touched.zipCode && formik.errors.zipCode}
						/>
					</div>
					<div className="col-span-6">
						<InputLabel id="city-label">
							City <span className="text-[#FF4D4F]">*</span>
						</InputLabel>

						<TextField
							style={{ marginTop: '12px' }}
							variant="outlined"
							fullWidth
							id="cityOfResidence"
							name="cityOfResidence"
							value={formik.values.cityOfResidence}
							onChange={formik.handleChange}
							error={formik.touched.cityOfResidence && Boolean(formik.errors.cityOfResidence)}
							helperText={formik.touched.cityOfResidence && formik.errors.cityOfResidence}
							disabled
						/>
					</div>
				</div>

				<InputLabel id="gender-label">
					Gender <span className="text-[#FF4D4F]">*</span>
				</InputLabel>

				<div className="my-4">
					<FormControl fullWidth>
						<Select
							variant="outlined"
							labelId="gender-label"
							id="gender"
							name="gender"
							value={formik.values.gender}
							onChange={formik.handleChange}
							error={formik.touched.gender && Boolean(formik.errors.gender)}>
							<MenuItem value={'Male'}>Male</MenuItem>
							<MenuItem value={'Female'}>Female</MenuItem>
							<MenuItem value={'Other'}>Other</MenuItem>
							<MenuItem value={'Not specified'}>Not specified</MenuItem>
						</Select>
					</FormControl>
				</div>

				<div className="my-4">
					<InputLabel id="bio-label">Bio</InputLabel>
					<TextField
						style={{ marginTop: '12px' }}
						variant="outlined"
						fullWidth
						id="bio"
						name="bio"
						multiline
						value={formik.values.bio}
						onChange={formik.handleChange}
						helperText={formik.touched.bio && formik.errors.bio}
					/>
				</div>

				<button className="bg-[#f3d24e] py-2 px-4 float-right" type="submit">
					Save
				</button>
			</form>
		</>
	);
};

export default ProfileTab;
