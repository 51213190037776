import { configureStore } from '@reduxjs/toolkit';
import { eventDetailApiService } from './features/EventDetail/service/eventDetail.service';
import eventDetailSlice from './features/EventDetail/service/eventDetail.slice';
import { eventsApiService } from './features/EventsListing/service/events.service';
import eventsSlice from './features/EventsListing/service/events.slice';
import { incomingRequestApiService } from './features/UserProfile/Incoming/service/incomingRequest.service';
import incomingRequestSlice from './features/UserProfile/Incoming/service/incomingRequest.slice';
import { myEventsApiService } from './features/UserProfile/MyEvents/services/myEvent.service';
import { myEventsSlice } from './features/UserProfile/MyEvents/services/myEvents.slice';
import { myMatchesApiService } from './features/UserProfile/MyMatches/services/myMatches.service';
import { myMatchesSlice } from './features/UserProfile/MyMatches/services/myMatches.slice';
import { outGoingRequestApiService } from './features/UserProfile/Outgoing/service/outgoingRequest.service';
import outgoingRequestsSlice from './features/UserProfile/Outgoing/service/outgoingRequest.slice';
import { userApiService } from './service/appAuth.service';
import appAuth from './service/appAuth.slice';
import { appSnackbarSlice } from './service/appSnackBarSlice';

export default configureStore({
	reducer: {
		appAuth: appAuth,
		[userApiService.reducerPath]: userApiService.reducer,
		eventsSlice: eventsSlice,
		[eventsApiService.reducerPath]: eventsApiService.reducer,
		eventDetailSlice: eventDetailSlice,
		[eventDetailApiService.reducerPath]: eventDetailApiService.reducer,
		[outGoingRequestApiService.reducerPath]: outGoingRequestApiService.reducer,
		outgoingRequestsSlice: outgoingRequestsSlice,
		[incomingRequestApiService.reducerPath]: incomingRequestApiService.reducer,
		incomingRequestSlice: incomingRequestSlice,
		appSnackBar: appSnackbarSlice.reducer,
		myEvents: myEventsSlice.reducer,
		[myEventsApiService.reducerPath]: myEventsApiService.reducer,
		myMatches: myMatchesSlice.reducer,
		[myMatchesApiService.reducerPath]: myMatchesApiService.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			userApiService.middleware,
			eventDetailApiService.middleware,
			eventsApiService.middleware,
			outGoingRequestApiService.middleware,
			incomingRequestApiService.middleware,
			myEventsApiService.middleware,
			myMatchesApiService.middleware
		)
});
